import { Component, OnInit } from '@angular/core';
import {StepsService} from "../steps.service";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export abstract class BaseFormComponent implements OnInit {
  abstract componentId: string;
  constructor(protected stepsService?: StepsService) {}

  ngOnInit(): void {
  }

  saveDataState(form: FormGroup, componentId): {[key: string]: any}{
    const data = {[componentId]: {}},
          innerData = data[componentId];
    Object.keys(form.controls).forEach((key) => {
      innerData[key] = form.get(key).value;
    });
    this.stepsService.updateFormDataState(data);
    return data;
  }

  getFormData(): BaseFormData{
    return (this.stepsService.data || {})[this.componentId];

  }

}

export interface BaseFormData{}
