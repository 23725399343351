
  <h4>
    Entrez vos informations pour calculer votre devis en ligne
  </h4>
  <div class="alert alert-danger">
    <ul>
      <li>Le champ ville est obligatoire</li>
    </ul>
  </div>
  <div class="separator"></div>
  <div class="form">
    <div class="form-group" [formGroup]="descriptionForm">
      <div class="row">
        <div class="col form-group">
          <label>Code postal</label>
          <input class="form-control form-control-lg" formControlName="cp"/>
        </div>
        <div class="col form-group">
          <label>Date de permis de construire</label>
          <select class="form-control form-control-lg custom-select" formControlName="dateConstruction">
            <option *ngFor="let opt of options['dateConstruction']" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label>Ville</label>
          <input class="form-control form-control-lg" formControlName="ville"/>
        </div>
        <div class="col form-group">
          <label>Nombre de pieces</label>
          <input class="form-control form-control-lg" formControlName="pieces"/>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label>Vente ou location*</label>
          <select class="form-control form-control-lg custom-select" formControlName="transactionType">
            <option *ngFor="let opt of options['transactionType']" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
        <div class="col form-group">
          <label>Installation Electrique</label>
          <select class="form-control form-control-lg custom-select" formControlName="installationPeriod">
            <option *ngFor="let opt of options['installationPeriod']" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label>Type de bien</label>
          <select class="form-control form-control-lg custom-select" formControlName="bienType">
            <option *ngFor="let opt of options['bienType']" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
        <div class="col form-group">
          <label>Installation gaz</label>
          <select class="form-control form-control-lg custom-select" formControlName="gazInstallationPeriod">
            <option *ngFor="let opt of options['gazInstallationPeriod']" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 form-group">
          <label>Coproriete</label>
          <select class="form-control form-control-lg custom-select" formControlName="coOwner">
            <option *ngFor="let opt of options['coOwner']" [value]="opt.id">{{opt.name}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
