<div class="container">
  <div class="header">
    <ul class="nav nav-pills" >
      <li class="nav-item" *ngFor="let step of wizardSteps">
        <a class="nav-link link" [class]='{active: routeSelected == step.id}' [routerLink]="step.id">{{step.name}}</a>
      </li>
    </ul>
  </div>

  <div class="body">
    <router-outlet (activate)="onActivate($event)" ></router-outlet>
  </div>

  <div class="footer">
    <button type="button" class="next btn btn-primary btn-lg" (click)="onClickNext()">{{buttonName}}</button>
  </div>
</div>




