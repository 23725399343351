import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReservationComponent } from './reservation/reservation.component';
import {WizardComponent} from "./wizard/wizard.component";
import {DescriptionComponent} from "./description/description.component";
import {DiagnosticComponent} from "./diagnostic/diagnostic.component";
import {RdvComponent} from "./rdv/rdv.component";
import {ConfirmationComponent} from "./confirmation/confirmation.component";

const routes: Routes = [{
	path: 'a/:id_agent',
	component: ReservationComponent,
},{
	path: '',
	component: ReservationComponent,
},{
  path: 'wizard',
  component: WizardComponent,
  children: [{
    path: 'desc',
    component: DescriptionComponent
  }, {
    path: 'diag',
    component: DiagnosticComponent
  }, {
    path: 'rdv',
    component: RdvComponent
  }, {
    path: 'confirmation',
    component: ConfirmationComponent
  }, {
    path: '**',
    redirectTo: 'desc',
    pathMatch: 'full'
  }]
}, {
	path: '**',
	redirectTo: '',
	pathMatch: 'full'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
