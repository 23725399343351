<div class="container">
  <h4 class="text-center">Vos diagnostics obligatoires et optionnels</h4>
  <div class="form">
    <div class="form-body">
        <h5 class="text-center">Vos diagnostics obligatoires</h5>
        <hr/>
        <div class="row">
          <div class="col-lg-12" *ngFor="let diag of getDiags()['must']">
            <div class="col-lg-3"></div>
            <div class="col-lg-4">
              <label class="form-check-label">{{diag.name}}</label>
            </div>
            <div class="col-lg-2">
              <input type="checkbox" [name]="'diag_' + diag.id " class="form-check-input" [checked]="diag.value"/>
            </div>
            <div class="col-lg-3"></div>
          </div>
        </div>
    </div>
    <br/>
    <div class="form-body">
      <h5>Vos diagnostics optionnels</h5>
      <hr/>
      <div class="row">
        <div class="col-lg-12" *ngFor="let diag of getDiags()['optional']">
          <div class="col-lg-3"></div>
          <div class="col-lg-4">
            <label class="form-check-label">{{diag.name}}</label>
          </div>
          <div class="col-lg-2">
            <input type="checkbox" [name]="'diag_' + diag.id " class="form-check-input" [checked]="diag.value"/>
          </div>
          <div class="col-lg-3"></div>
        </div>
      </div>
    </div>
  </div>
</div>
