import { Injectable } from '@angular/core';
import {Observable, Subject} from "rxjs";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  private nextStepAnnounce = new Subject<any>();
  private currentStepReport = new Subject<FormGroup>();
  $nextStepAnnounce = this.nextStepAnnounce as Observable<any>;
  $currentStepReport = this.currentStepReport as Observable<FormGroup>;
  data: {[key: string]: any};

  stepAnnounced(data: any){
    this.nextStepAnnounce.next(data);
  }

  stepReport(data: FormGroup){
    this.currentStepReport.next(data);
  }

  updateFormDataState(data: {[key: string]: any}){
    this.data = {...this.data, ...data};
  }

}
