


<div class='big_div' *ngIf="page_confirmation">

    <h1 class="text-style-2 layout-page-title">CONFIRMATION </h1>

    <div class="bc-blue confirmation-wrapper">

        <h1 class='color-white mb-5 text-italic'>Félicitations !</h1>
        <p class="color-white m-5p text-center mb-5">
          <span class="text-italic">Votre demande de rendez-vous</span>
          <span class="text-strong" *ngIf="jour"> {{ jour * 1000 | date: "EEEE dd MMMM yyyy" | titlecase }}</span>
          <span class="text-strong" *ngIf="heure"> à {{ heure * 1000 | date:"HH"}}h{{ heure * 1000 | date:"mm" }}</span>
          <span class="text-italic"> a bien été prise en compte</span>
        </p>

        <p class="color-white text-center text-italic mb-5">Vous recevrez un email et un sms de confirmation</p>

        <p class="color-white text-center mb-5">Votre conseiller I.D.Z Consulting reste à votre disposition : </p>

        <h3 class="color-white m-5p text-center text-strong">
            Par téléphone au 01-77-38-09-79
        </h3>

        <h3 class="color-white m-5p text-center text-strong">
            Par WhatsApp au 07-55-53-23-33
        </h3>
    </div>

</div>



<div class='big_div' *ngIf="page_reservation" [formGroup]="RDV">
    <button class="btn btn-precedant-wrapper" (click)="GoBackFromPageRDV()">
      <span class="retour-fleche">←</span>
    </button>
    <h1 class="text-style-2 layout-page-title">Réserver un RDV </h1>

    <select class="form-control select-input my-5" formControlName="jour" (change)="onDaySelected($event)" #mySelectDay>
      <option [value]="null">Choisissez le jour qui vous convient</option>
      <option *ngFor="let day of creneaux" [ngValue]="day[0]">
          <label>
              <span class="label select"> {{ day[0] * 1000 | date: "EEEE dd MMMM yyyy" }}</span>
          </label>
      </option>
    </select>

    <select class="form-control select-input my-5" formControlName="heure" #mySelectHour>
      <option [value]="null">Choisissez l’heure qui vous convient</option>
      <option *ngFor="let hour of hours" [ngValue]="hour">
          <label>
              <span class="label select">{{ hour * 1000 | date:"HH"}}h{{ hour * 1000 | date:"mm" }}</span>
          </label>
      </option>
    </select>


    <div class="btn-suivant-wrapper">
        <button class="label success btn btn-success">
            <span class="btn-suivant-text" (click)='GoPagePrix()'>→
            </span>
        </button>
    </div>

</div>



<div class='big_div' *ngIf="page_rapports">
    <button class="btn btn-precedant-wrapper" (click)="GoBackFromPageRapports()">
      <span class="retour-fleche">←</span>
    </button>

    <h1 class="text-style-2 layout-page-title"> ENVOI DES RAPPORTS</h1>
    <h3 class="text-center m-5p color-red">Vous pouvez choisir d'envoyer directement les rapports de diagnostics à votre agent immobilier et/ou à votre notaire</h3>

    <div class="rapports-checkbox-wrapper mt-5">

      <label>
          <span class="label">
              <mat-checkbox [(ngModel)]="send_agent">
                  <label>Envoyer à mon agent immobilier</label>
              </mat-checkbox>
          </span>
      </label>


      <label>
          <span class="label">
              <mat-checkbox [(ngModel)]="send_notaire">
                  <label> Envoyer à mon notaire</label>
              </mat-checkbox>
          </span>
      </label>

    </div>

    <div class="btn-suivant-wrapper">
        <button class="label success btn btn-success">
            <span class="btn-suivant-text" (click)="GoPageAgents()">→</span>
        </button>
    </div>


</div>

<div  class='big_div' *ngIf="page_notaire">

    <button class="btn btn-precedant-wrapper" (click)="GoBackFromPageNotaire()">
        <span class="retour-fleche">←</span>
    </button>


  <div [formGroup]="Notaire" *ngIf="send_notaire">
    <h1 class="text-style-2 layout-page-title">JE PARTAGE AVEC MON NOTAIRE</h1>
    <h3 class="text-center color-red m-5p text-italic">Veuillez renseigner les informations suivantes sur votre notaire</h3>

    <div>
        <input type="tel" #numnotaire (input)="getNotaire(numnotaire.value)" class="form-control text-input" formControlName="phone_number" placeholder="Numero de portable" />

        <input type="text" class="form-control text-input" autocomplete="off" formControlName="mail" placeholder="Adresse Email" />

        <input type="text"  #nomnotaire   class="form-control text-input" formControlName="complete_name" placeholder="Nom Complet" />
    </div>

    <div class="btn-suivant-wrapper">
        <button [className]="Notaire.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Notaire.invalid">
            <span class="btn-suivant-text"  (click)="GoFromNotaire()">→</span>
        </button>
    </div>

    </div>
</div>




<div class='big_div' *ngIf="page_agent">

    <button class="btn btn-precedant-wrapper" (click)="GoBackFromPageAgent()">
      <span class="retour-fleche">←</span>
    </button>

    <div [formGroup]="Agent">
        <h1 class="text-style-2 layout-page-title">JE PARTAGE AVEC MON AGENT IMMOBILIER</h1>
        <h3 class="color-red text-center m-5p text-italic">Veuillez renseigner les informations suivantes sur votre agent immobilier</h3>

        <div>
            <input type="tel" #numagent (input)="getAgentImmo(numagent.value)" class="form-control text-input" formControlName="phone_number" placeholder="Numero de portable" />

            <input type="text" class="form-control text-input"  autocomplete="off" formControlName="mail" placeholder="Adresse Email" />

            <input type="text"  #nomagent  class="form-control text-input" formControlName="complete_name" placeholder="Nom Complet" />

            <input type="text" class="form-control text-input" formControlName="agency" placeholder="Mon Agence" />
        </div>

        <div class="btn-suivant-wrapper">
            <button [className]="Agent.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Agent.invalid">
                <span class="btn-suivant-text"  (click)="GoFromAgent()">→
                </span>
            </button>
        </div>

    </div>

</div>


<div [formGroup]="Reservation">

    <div class='big_div recap' *ngIf="page_recap">
        <button class="btn btn-precedant-wrapper" (click)='GoBackFromPageRecap()'>
          <span class="retour-fleche">←</span>
        </button>

        <h1 class="text-style-2 layout-page-title"> DEVIS </h1>
        <h3 class="text-center color-red text-italic mb-0">Voici notre tarif pour la réalisation des diagnostics obligatoires concernant votre bien: </h3>

        <div class="col-full annonce-tarif">{{ prix }} € </div>

        <div class="btn-suivant-wrapper">
            <button class="label success btn btn-success">
                <span class="btn-suivant-text" (click)="GoPageReservation()">→</span></button>
        </div>

        <div class="lowPanel">
          <div class="row" #recapitulatif>
              <h2 class="text-center">RÉCAPITULATIF</h2>
              <div formGroupName="step1" *ngFor="let transaction of refs.type_transaction">
                  <input type="text" style="display: none;" #mytransaction formControlName="transaction">
                  <label *ngIf="transaction.id == mytransaction.value">
                      <span> Transaction : {{transaction.nom}}</span>
                  </label>
              </div>

              <div>
                  <label>
                      <span> Adresse d'intervention : {{ Reservation.get('step1').get('city').value }}, {{ Reservation.get('step1').get('code_postal').value }}</span>
                  </label>
              </div>

              <div formGroupName="step2" *ngFor="let bien of refs.type_bien">
                  <input type="text" style="display: none;" #mybien formControlName="bien">
                  <label *ngIf="bien.id == mybien.value">
                      <span> Type de bien : {{bien.nom}}</span>
                  </label>
              </div>

              <div formGroupName="step3" *ngFor="let construction of refs.date_construction">
                  <input type="text" style="display: none;" #myconstruction formControlName="construction">
                  <label *ngIf="construction.id == myconstruction.value">
                      <span> Annee de construction : {{construction.nom}}</span>
                  </label>
              </div>
              <div formGroupName="step4" *ngFor="let surface of refs.type_surface">
                  <input type="text" style="display: none;" #mysurface formControlName="surface">
                  <label *ngIf="surface.id == mysurface.value">
                      <span> Surface : {{surface.nom}}</span>
                  </label>
              </div>
              <div #diagnos>
                  <label>
                      <span>Diagnostic :</span>
                  </label>
                  <label *ngFor="let nom of noms_diagnostiques_necessaires; let i=index">
                      <span style="font-size: 16px; "> <label *ngIf="i">, </label> {{nom}}</span>
                  </label>
              </div>

            <div formGroupName="step6">
              <input type="text" style="display: none;" #note formControlName="note">
              <label *ngIf="note.value">
                <span> Note : {{note.value}}</span>
              </label>
            </div>
            <div>
              <label>
                <span> Prix : {{ prix }} €</span>
              </label>
            </div>
          </div>
          <div class="shareRow">
          <div class="share whatsapp" (click)="share(0)">
            <div class="icon"></div>
            <div class="fleche icon"></div>
          </div>
          <div class="share email" (click)="share(1)">
            <div class="icon"></div>
            <div class="fleche icon"></div>
          </div>
          <div class="share sms" (click)="share(2)">
            <div class="icon"></div>
            <div class="fleche icon"></div>
          </div>
        </div>
        </div>
        <h3 class="text-center color-red text-italic mb-0" *ngIf="diagnostiquesObligatoireEnPossession?.length">
            <span>Vous avez décoché {{ diagnostiquesObligatoireEnPossession?.length>1 ? 'les diagnostics' : 'le diagnostic' }} </span>
            <span *ngFor="let diag of diagnostiquesObligatoireEnPossession">{{ diag.name }}, </span>
            <span>car vous l'avez déjà en votre possession et à jour de validité. Merci de bien vouloir le présenter le jour de l'intervention.</span>
        </h3>

    </div>

    <div class="big_div" formGroupName="step5" *ngIf="page_shortUserInfos">
      <button class="btn btn-precedant-wrapper" (click)="GoPage4()">
        <span class="retour-fleche">←</span>
      </button>
      <div class="content">
        <h1 class="text-style-2 layout-page-title"> Pour recevoir un devis immediat, veuillez renseigner quelques informations personnelles </h1>
        <input type="text" class="form-control text-input" formControlName="complete_name" placeholder="Nom Complet" autocomplete="nope"/>
        <input type="tel" class="form-control text-input" formControlName="phone_number" placeholder="Numéro de portable" autocomplete="nope" />
          <input type="text" autocomplete="off" class="form-control text-input" formControlName="mail" placeholder="Adresse Email" autocomplete="nope" />
      </div>
      <div class="btn-suivant-wrapper">
        <button [className]="Reservation.controls.step5.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Reservation.controls.step5.invalid || isLoadingNextPage" (click)='GoPageDiagnostics()'>
          <span class="btn-suivant-text">→</span></button>
      </div>
    </div>


    <div class='big_div' formGroupName="step6" *ngIf="page_prix">
    <button class="btn btn-precedant-wrapper" (click)="GoBackFromPageCoordonees()">
      <span class="retour-fleche">←</span>
    </button>

    <h1 class="text-style-2 layout-page-title"> Coordonnees du proprietaire </h1>

    <h3 class="text-center color-red text-italic">Veuillez renseigner les informations suivantes afin d'obtenir le prix automatiquement</h3>


    <div style="margin : 20px 20px">
      <input type="text" class="form-control text-input" formControlName="complete_name" placeholder="Nom Complet" autocomplete="nope"/>

      <input #adresse_street type="text" class="form-control text-input" formControlName="streetNum" placeholder="Adresse complete (etage, porte)" autocomplete="nope"/>


      <input type="tel" class="form-control text-input" formControlName="phone_number" placeholder="Numéro de portable" autocomplete="nope" />

      <input type="text" autocomplete="off" class="form-control text-input" formControlName="mail" placeholder="Adresse Email" autocomplete="nope" />

      <textarea #note class="form-control text-input" formControlName="note" placeholder="Note" autocomplete="nope"></textarea>


      <div class="noteContainer" formGroupName="note2">
        <div class="title">Notes supplementaires</div>
        <div class="noteContainer__item" *ngFor="let item of notesFields"  [class]='item.css'>
          <div class="left" *ngIf="item.caption != undefined"> {{ item.caption || '' }}:</div>

          <ng-container [ngSwitch]="item.templateRef">
            <ng-container *ngSwitchCase="'radioGroup'">
              <ng-container *ngTemplateOutlet="radioGroup; context: {item: item, formGroup: Reservation.get('step6').get('note2')}"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'input'" [class]='item.css'>
              <ng-container *ngTemplateOutlet="input;context: {item: item, formGroup: Reservation.get('step6').get('note2')}"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="'select'" [class]='item.css'>
              <ng-container *ngTemplateOutlet="select; context: {item: item, formGroup: Reservation.get('step6').get('note2')}"></ng-container>
            </ng-container>
          </ng-container>
        </div>


      </div>


    </div>

    <ng-template #radioGroup let-field='item' let-formgroup='formGroup'>
      <div class="btn-group" role="group">
        <ng-container *ngFor="let option of field.options">
          <div class="radioWrapper btn btn-lg btn-outline-darkblue" [class]="{'active': field.value == option.id}" (click)="onInputChanged($event, field, option.id, formgroup)">
            <label [for]="option.id"> {{ option.caption }} </label>
            <input type="radio" [id]="option.id" [value]="option.id" [checked]="field.value == option.id" style="display: none;"/>
          </div>

        </ng-container>

      </div>
    </ng-template>
    <ng-template #input let-field='item' let-formgroup='formGroup'>
      <input [class]='field?.inputCss || "form-control text-input"' [placeholder]="field?.placeholder || ''" [type]="field?.type || 'input'" [formControl]="formgroup.get(field.id)" (change)="onInputChanged($event, field, null, formgroup)" [checked]="isCheckboxActive(field, formgroup)"/>
      <div class="errors" *ngIf="formgroup.get(field.id).invalid && formgroup.get(field.id).errors && (formgroup.get(field.id).touched || formgroup.get(field.id).dirty)">
          <span *ngIf="formgroup.get(field.id).hasError('required')" class="text-danger">
            * Champ obligatoire
          </span>
        <span *ngIf="formgroup.get(field.id).hasError('pattern')" class="text-danger">
            * Veuillez saisir une valeur numerique uniquement
          </span>
        <span *ngIf="formgroup.get(field.id).hasError('email')" class="text-danger">
            * Veuillez saisir un email
          </span>
      </div>
    </ng-template>

    <ng-template #select let-field='item' let-formgroup='formGroup'>
      <select class='form-select form-select-lg' [formControl]="formgroup.get(field.id)" (change)="field.onChange && field.onChange($event)">
        <option *ngFor="let option of field.options" [value]="option.id">
          {{option.caption}}
        </option>
      </select>
    </ng-template>

    <div class="btn-suivant-wrapper">
      <button [className]="Reservation.controls.step6.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Reservation.controls.step6.invalid || isLoadingNextPage" (click)='GoPageRapports()'>
        <span class="btn-suivant-text">→</span></button>
    </div>


  </div>

    <div class='big_div' formGroupName="step7" *ngIf="page_diagnostics">


        <button class="btn btn-precedant-wrapper" (click)="GoBackFromPageDiagnos()">
          <span class="retour-fleche">←</span>
        </button>

        <h1 class="text-style-2 layout-page-title">DIAGNOSTICS OBLIGATOIRES </h1>

        <h3 class="text-center color-red text-italic mb-0">Voici les diagnostics obligatoires (en vert)</h3>

        <h3 class="color-red m-5p text-center">À décocher: les diagnostics obligatoires déjà en votre possession
        </h3>


        <div class="row diagnostics-list">
            <div class="col-half" *ngFor="let diag of diagnostiques; let i=index">
                <!-- The repeated alias template -->
                <div *ngIf="diag.obligatoire == 1">
                    <label *ngIf="!diag.possession">
                        <span (click)='possession(i); diag.possession = 1 ' class="label select" [style]="diag.possession ? ' background-color:orange; border-color: orange;' : 'background-color:green; border-color: green;' + ' color: white;'"> {{ diag.name }} </span>
                    </label>
                    <label *ngIf="diag.possession">
                        <span (click)='not_possession(i); diag.possession = 0 ' class="label select" [style]="diag.possession ? ' background-color:orange; color: white; border-color: orange;' : 'background-color:green; border-color: green;'"> {{ diag.name }} </span>
                    </label>
                </div>
                <div *ngIf="!diag.obligatoire">
                    <label *ngIf="!diag.option">
                        <span (click)='option(i); diag.option = 1 ' [style]=" diag.option ? ' background-color:green; color: white; border-color: green;' : ''" class="label select"> {{ diag.name }} </span>
                    </label>
                    <label *ngIf="diag.option">
                        <span (click)='not_option(i); diag.option = 0' [style]=" diag.option ? ' background-color:green; color: white; border-color: green;' : ''" class="label select"> {{ diag.name }} </span>
                    </label>
                </div>




            </div>
        </div>

        <div class="col-full annonce-tarif"> Prix TTC : {{ prix }} € </div>


        <div class="btn-suivant-wrapper">
            <button class="label success btn btn-success">
                <span class="btn-suivant-text" (click)='GoPageRecap()'>→
                </span>
            </button>
        </div>

    </div>






<!--    <div class='big_div' formGroupName="step5" *ngIf="page_adresse">-->

<!--        <button class="btn btn-precedant-wrapper" (click)="BackPage4()">-->
<!--          <span class="retour-fleche">←</span>-->
<!--        </button>-->

<!--        <h1 class="text-style-2 layout-page-title">L'ADRESSE DU BIEN </h1>-->



<!--        -->


<!--&lt;!&ndash;        <div (click)="GoToAdress()" class="color-blue text-center"><u> Adresse Introuvable ? </u> </div>&ndash;&gt;-->

<!--        <div class="btn-suivant-wrapper">-->
<!--            <button [className]="Reservation.controls.step5.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Reservation.controls.step5.invalid" (click)='GoPageDiagnostics()'>-->
<!--                <span class="btn-suivant-text">→</span></button>-->
<!--        </div>-->


<!--    </div>-->


    <div class='big_div' formGroupName="step4" *ngIf="page_surface">

        <button class="btn btn-precedant-wrapper" (click)="BackPage3()">
          <span class="retour-fleche">←</span>
        </button>

        <h1 class="text-style-2 layout-page-title">LA SUPERFICIE DU BIEN </h1>


        <div class="row">
            <div class="col-half" *ngFor="let surface of refs.type_surface" (change)='GoPage5()' (click)="selectUniqueOption($event, 4)">
                <label>
                    <input type="radio" formControlName="surface" [value]="surface.id" style="display: none;">
                    <span class="label select">{{ surface.nom}}</span>
                </label>
            </div>
        </div>

        <div class="btn-suivant-wrapper">
            <button [className]="Reservation.controls.step4.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Reservation.controls.step4.invalid" (click)='GoPage5()'>
                <span class="btn-suivant-text">→</span></button>
        </div>
    </div>


    <div class='big_div' formGroupName="step3" *ngIf="page_construction">

        <button class="btn btn-precedant-wrapper" (click)="BackPage2()">
          <span class="retour-fleche">←</span>
        </button>

        <h1 class="text-style-2 layout-page-title">CONSTRUCTION DU BIEN </h1>


        <div class="row ">
            <div class="col-half" *ngFor="let date of refs.date_construction" (change)='GoPage4()' (click)="selectUniqueOption($event,3)">
                <label>
                    <input type="radio" formControlName="construction" [value]="date.id" style="display: none;">
                    <span class="label select">{{date.nom}}</span>
                </label>
            </div>
        </div>

        <div class="btn-suivant-wrapper">
            <button [className]="Reservation.controls.step3.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Reservation.controls.step3.invalid" (click)='GoPage4()'>
                <span class="btn-suivant-text">→</span></button>
        </div>
    </div>


    <div class='big_div' formGroupName="step1" *ngIf="page_transaction">
        <img class="logo" src="https://idzconsulting.fr/res/img/logo-idz-450x130.png" />


        <h2 class='text-style-1'>Devis et prise de
            rendez-vous en 2 minutes</h2>


        <h1 class='text-style-2 layout-page-title'>CODE POSTAL</h1>
      <div class="code_postal">
        <input #code_postal class="form-control text-input" type="text" placeholder="Saisir le code postal" formControlName="code_postal" autocomplete="nope"/>
        <div *ngIf="SearchAdressStatus.running" class="text-center color-red">Recherche en cours...</div>
        <div *ngIf="SearchAdressStatus.ended && SearchAdressStatus.noResults" class="text-center color-red">Aucun résultat. Continuez de chercher.</div>
        <div class="addressMenu" [ngClass]="{hidden: hideAddressMenu}">
          <div class="col-full" *ngFor="let adresse of adresses | async" (click)="updateCity(adresse)" >
            <label>
              <span class="option-adresse"> {{adresse.city}}</span>
            </label>
          </div>
        </div>
        <input #adresse_ville type="text" class="form-control text-input" formControlName="city" placeholder="Saisir la commune ou la ville" autocomplete="nope" />
      </div>
        <div class="row">
          <div class="col-half" *ngFor="let transaction of refs.type_transaction" (change)="GoPage2()" (click)="selectUniqueOption($event, 1)">
              <label>
                  <input type="radio" formControlName="transaction" [value]="transaction.id" style="display: none;">
                  <span class="label select">{{transaction.nom}}</span>
              </label>
          </div>
        </div>


        <div class="btn-suivant-wrapper">
            <!-- <button  #Suivant1  [disabled]="selected_transaction ? false : true" (click)='GoPage2()'> -->
            <button [className]="Reservation.controls.step1.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]='Reservation.controls.step1.invalid' (click)='GoPage2()'>
              <span>→</span>
            </button>
        </div>
    </div>


    <div class='big_div' formGroupName="step2" *ngIf="page_bien">
        <button class="btn btn-precedant-wrapper" (click)="BackPage1()">
          <span class="retour-fleche">←</span>
        </button>

        <h1 class="text-style-2 layout-page-title">VOTRE BIEN </h1>


        <div class="row">
            <div class="col-half" *ngFor="let bien of refs.type_bien" (change)="GoPage3()" (click)="selectUniqueOption($event, 2)">
                <label>
                    <input type="radio" formControlName="bien" [value]="bien.id" style="display: none;">
                    <span class="label select">{{bien.nom}}</span>
                </label>
            </div>
        </div>

        <div class="btn-suivant-wrapper">
            <button [className]="Reservation.controls.step2.invalid ? ' label success btn ' : 'label success btn btn-success' " [disabled]="Reservation.controls.step2.invalid" (click)='GoPage3()'>
                <span>→</span></button>
        </div>
        <div>

        </div>

    </div>

</div>

