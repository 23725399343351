import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DescriptionComponent} from '../description/description.component';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {StepsService} from '../steps.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit {
  public wizardSteps: WizardStep[] = [{id: 'desc', name: 'Description du bien'}, {id: 'diag', name: 'Vos diagnostiques'}, {id: 'rdv', name: 'Votre choix de RDV'}, {id: 'confirmation', name: 'Votre confirmation'}];
  public activatedStepIndx = 0;
  description: DescriptionComponent;
  cp: string;
  routeSelected: string;
  subscribeObj: Subscription;
  buttonName = 'Suivant';
  constructor(private stepsService: StepsService, private _router: Router, private activateRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.subscribeObj = this.stepsService.$currentStepReport.subscribe((formGroup) => {
      console.log(formGroup);
    });
  }

  announceNextStep(stepIndx){
    this.stepsService.stepAnnounced(stepIndx);
  }


  onActivate(componentRef){
    this.routeSelected = componentRef.componentId;
    let activatedIndx;
    this.wizardSteps.forEach((step, i ) => {
      // tslint:disable-next-line:triple-equals
      if (step.id == componentRef.componentId) {
        activatedIndx = i;
      }
    });
    this.updateActivatedStepIndx(activatedIndx);
    this.updateButtonName();
  }

  updateButtonName(){
    if (this.isLastStep()) {
      this.buttonName = 'Confirmation';
    }
    else {
      this.buttonName = 'Suivant';
    }
  }
  updateActivatedStepIndx(indx){
    this.activatedStepIndx = indx;
  }

  setRoute(routeChildPath: string){
    this.routeSelected = routeChildPath;
  }

  isLastStep(){
    return this.activatedStepIndx == this.wizardSteps.length - 1;
  }

  onClickNext(){
    if (this.isLastStep()) {
      return;
    }

    this.activatedStepIndx++;
    this.announceNextStep(this.activatedStepIndx);
    this._router.navigate([this.wizardSteps[this.activatedStepIndx].id], {relativeTo: this.activateRoute});
  }

  ngOnDestroy(){
    this.subscribeObj.unsubscribe();
  }

}

interface WizardStep{
  id: string;
  name: string;
}

