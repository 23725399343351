import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
const base_url = environment.api_base_url;

@Injectable({
  providedIn: 'root'
})
export class DataService {



  constructor(private http : HttpClient) {}

  refs_url = base_url + '/refs';

  private loadRefs() : Observable<any>
  {
    return this.http.get(this.refs_url).pipe(map(res => {
      sessionStorage.setItem('refs', JSON.stringify(res))
      return res;
    }));
  }

  // Permet publiquement d'obtenir un certain array de references selon sa 'key'
  public getRefs(key?: string) {
    let refs = JSON.parse(sessionStorage.getItem('refs'));
    if(!refs){
      return this.loadRefs().pipe(map(refs => {
        if(key)
          return refs[key];
        else
          return refs;
      }));
    } else {
      if(key)
        return of(refs[key]);
      else
        return of(refs);
    }

    if(key)
      return of(refs[key]);
    else
      return of(refs);
  }

  public postStep(stepsData: any): Observable<{error: boolean; insert_mission: string;}>{
      return this.http.post<any>(base_url  + `/rendez-vous/inserer`, stepsData);
  }


  public postAdress( adresse : any) : any

  {
    return this.http.post<any>('https://apidev.rdvexpress.idzconsulting.fr'  + '/adresse/recherche', { recherche :  adresse });

  }

  public postConfirmation(s : any)
  {
    return this.http.post<any>(base_url  + '/rendez-vous/nouveau',s);
  }

  public getRDV()
  {
    return this.http.post<any>(base_url  + '/rendez-vous/disponibles',{});
  }

  public postPrix(s : any)
  {
    console.log(s);
    return this.http.post<any>(base_url  + '/diagnostics/prix', s);
  }


  public postAgent(s)
  {
    return this.http.post<any>(base_url  + '/partenaires/recherche', s);
  }



  public createAgent(s)
  {
    return this.http.post<any>(base_url  + '/contacts/ajout', s);
  }


  //#region "Functions to manage data from the server"

  public getRef_Transaction() : any
  {
     return this.getRefs("type_transaction")
  }

  public getRef_Bien() : any
  {
    return this.getRefs("type_bien")
  }

  public getRef_Construction() : any
  {
    return this.getRefs("ref_construction")
  }


  public getRef_Surface() : any
  {
    return this.getRefs("ref_surface")
  }

  public getDiagnostics(s : any ) : any
  {
    console.log(s);
    return this.http.post<any>(base_url  + '/diagnostics/obligatoires', s );
  }

  public get_Refs() : any
  {
    return this.http.get(base_url );
  }

  public getAgentImmoByID(id) : any
  {
    return this.http.post<any>(base_url  + '/partenaires/agent', { id });
  }

//#region  References

public transactions = [] ;


//#endregion





}
