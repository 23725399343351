import { Component, OnInit } from '@angular/core';
import {BaseFormComponent} from "../base-form/base-form.component";
import {StepsService} from "../steps.service";

@Component({
  selector: 'app-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.scss']
})
export class DiagnosticComponent extends BaseFormComponent implements OnInit {
  componentId: string = 'diag';
  constructor(stepsService: StepsService) {
    super(stepsService);
  }

  ngOnInit(): void {
  }

  getDiags(): Diag{
    const diags = {must: [{
        id: 'plomb',
        name: 'Diagnostic plomb',
        value: true

      }, {
        id: 'amiante',
        name: 'Diagnostic amiante',
        value: true
      }, {
        id: 'dpe',
        name: 'DPE',
        value: true
      }],
      optional: [{
        id: 'Loi carrez',
        name: 'carrez',
        value: false
      }]};
    return diags;
  }



}

interface Diag{
  must: DiagEntity[];
  optional: DiagEntity[];
}

interface DiagEntity{
  id: string;
  name: string;
  value: boolean;

}
