import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {StepsService} from "../steps.service";
import {Subscription} from "rxjs";
import {BaseFormComponent, BaseFormData} from "../base-form/base-form.component";

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class DescriptionComponent extends BaseFormComponent implements OnInit {
  @Input() formData: DescriptionFormData;
  descriptionForm: FormGroup;
  componentId: string = 'desc';
  subscribeObj: Subscription;
  options: {[key: string]: {id: string; name: string}[]} = {
    dateConstruction: [{
      id: 'before1949',
      name: 'Avant le 01/01/1949'
    }, {
      id: '1949_1997',
      name: 'Entre 1949 et le 01/07/1997'
    }, {
      id: 'after1997',
      name: 'Après le 01/07/1997'
    }],
    transactionType: [{
      id: 'sale',
      name: 'Vente'
    }, {
      id: 'rent',
      name: 'Location'
    }],
    installationPeriod: [{
      id: 'under15',
      name: '- de 15ans'
    }, {
      id: 'above15',
      name: '+ de 15 ans'
    }],
    bienType: [{
      id: 'house',
      name: 'Maison'
    }, {
      id: 'appartment',
      name: 'Appartement'
    }, {
      id: 'building',
      name: 'Immeuble ou local commercial'
    }, {
      id: 'cellar_box',
      name: 'Cave ou box'
    }],
    gazInstallationPeriod: [{
      id: 'under15',
      name: '- de 15ans'
    }, {
      id: 'above15',
      name: '+ de 15 ans'
    }],
    coOwner: [{
      id: 'without',
      name: 'Sans'
    }, {
      id: 'with',
      name: 'Avec'
    }]
  };

  constructor(stepsService: StepsService) {
    super(stepsService);
  }

  ngOnInit(): void {
    const data = (this.getFormData() || {}) as DescriptionFormData;
    this.descriptionForm = new FormGroup({
      cp: new FormControl(data.cp || '', Validators.required),
      dateConstruction: new FormControl(data.dateConstruction || this.options.dateConstruction[0].id),
      ville: new FormControl(data.ville || ''),
      pieces: new FormControl(data.pieces || ''),
      transactionType: new FormControl(data.transactionType || this.options.transactionType[0].id),
      installationPeriod: new FormControl(data.installationPeriod || this.options.installationPeriod[0].id),
      bienType: new FormControl(data.bienType || this.options.bienType[0].id),
      gazInstallationPeriod: new FormControl(data.gazInstallationPeriod || this.options.gazInstallationPeriod[0].id),
      coOwner: new FormControl(data.coOwner || this.options.coOwner[0].id)
    });

    this.subscribeObj = this.stepsService.$nextStepAnnounce.subscribe(() => {
      this.saveDataState(this.descriptionForm, this.componentId);
    });

  }


  ngOnDestroy(){

    this.subscribeObj.unsubscribe();
  }



}

interface DescriptionFormData extends BaseFormData{
  cp: number;
  ville: string;
  transactionType: string;
  saleOrRent: 0 | 1;
  realEstateType: number;
  coOwner: 0 | 1;
  dateOfBuildPermission: number;
  pieces: number;
  installationPeriod: 0 | 1;
  gazInstallationPeriod: 0 | 1;
  dateConstruction: string;
  bienType: string;
}
