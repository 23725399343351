import {Component, OnInit, ViewChild} from '@angular/core';
import { DataService } from '../data-service.service';
import { HttpClient }  from '@angular/common/http';
import {FormGroup, FormBuilder, Validators, AbstractControl, FormControl} from '@angular/forms';
import { debounceTime, startWith, switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute }  from '@angular/router';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {LifecycleHooks} from "@angular/compiler/src/lifecycle_reflector";
import {F} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {



  public refs : any = {};
  public SearchAdressStatus: any = {};
  public adresses:Observable<any>;
  public city: Observable<string>;
  public diagnostiques = [];
  public diagnostiques_obligatoires = [];
  public diagnostiques_necessaires = [];
  public noms_diagnostiques_necessaires= [] ;
  public prix;
  public send_agent = true;
  public send_notaire = false;
  public canConfirm = true ;
  public canSendAgent = true;
  public canSendNotaire = true;
  public isConfirmed = true;
  public rdv_jour = new Date().toJSON();
  public page_transaction= true;
  public page_bien= false;
  public page_construction = false;
  public page_surface= false;
  public page_shortUserInfos= false;
  public page_adresse = false;
  public page_nouvelleadresse = false;
  public page_prix = false;
  public page_diagnostics = false;
  public page_recap = false;
  public page_reservation = false;
  public page_rapports= false;
  public page_agent = false;
  public page_notaire = false;
  public page_confirmation = false;
  public id_agent = null;
  public tel_agent = "0";
  public id_notaire = null;
  public tel_notaire = "0" ;
  public creneaux = [];
  public hours = [];
  public jour;
  public heure;
  public Reservation: FormGroup;
  public Agent: FormGroup;
  public Notaire: FormGroup;
  public RDV: FormGroup;
  public isLoadingNextPage: boolean = false;
  public dataObj = {};
  public hideAddressMenu: boolean = false;
  public selectedCommune: string = '';
  public notesFields: {id: string; caption?: string; type: string; placeholder?: string; css?: string; templateRef?: string; value?: any; options?: {id: string; caption: string;}[]; attachedFieldIds?: string[], inputCss?: string; onChange?: (field?: any) => void}[] = [ {
    id: 'client',
    caption: 'Qui sera sur place',
    type: 'select',
    templateRef: 'select',
    options: [{
      id: 'Proprietaire',
      caption: 'Proprietaire'
    },{
      id: 'Agent',
      caption: 'Agent immobillier'
    }, {
      id: 'Locataire',
      caption: 'Locataire'
    }],
    onChange: ($event) => {
        const val = $event.target.value,
          relevantFields = this.notesFields.filter(({id}) => ['nomlocataire', 'tellocataire'].includes(id));
        relevantFields.forEach((field) => {
          field.css = val == 'Locataire' ? '' : 'hide';
          const formControl = this.Reservation.get('step6').get('note2').get(field.id);
          if(field.css == 'hide')
            formControl.disable();

          else
            formControl.enable();

        });
    }
  },{
      id: 'nomlocataire',
      caption: 'Nom du locataire',
      type: 'text',
      templateRef: 'input',
      placeholder: 'Saisir Nom complet',
      css: 'hide'
  },{
      id: 'tellocataire',
      caption: 'Telephone du locataire',
      type: 'text',
      templateRef: 'input',
      placeholder: 'Saisir Tel',
      css: 'hide'
  },{
    id: 'chauffage',
    caption: 'Chauffage Collectif',
    type: 'radio',
    value: 0,
    templateRef: 'radioGroup',
    options: [ {
      id: '0',
      caption: 'Non'
    }, {
      id: '1',
      caption: 'Oui'
    }],
    attachedFieldIds: ['syndicname', 'syndictel', 'syndicmail']
  }, {
    id: 'syndicname',
    caption: 'Nom du syndic',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Saisir nom',
    css: 'hide'

  }, {
    id: 'syndictel',
    caption: 'Telelphone du syndic',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Saisir tel',
    css: 'hide'

  }, {
    id: 'syndicmail',
    caption: 'Mail du syndic',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Saisir mail',
    css: 'hide'

  }, {
    id: 'surface',
    caption: 'Surface precise (m2)',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Saisir la surface'
  }, {
    id: 'construction',
    caption: 'Annee exacte de construction',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Saisir annee de construction'
  },{
    id: 'facturation',
    caption: 'Qui facturer',
    type: 'select',
    templateRef: 'select',
    options: [{
      id: 'Proprietaire',
      caption: 'Proprietaire'
    }, {
      id: 'Notaire',
      caption: 'Notaire'
    }, {
      id: 'Agent',
      caption: 'Agent immobillier'
    }]
  }, {
    id: 'autre',
    caption: 'Les cles sont a recuperer',
    type: 'checkbox',
    templateRef: 'input',
    inputCss: ' ',
    value: false,
    attachedFieldIds: ['cleaddress', 'cletel', 'clemail'],
    css: 'noMargin',
  }, {
    id: 'cleaddress',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Addresse',
    css: 'noMargin hide'
  }, {
    id: 'cletel',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Telephone',
    css: 'noMargin hide'
  },{
    id: 'clemail',
    type: 'text',
    templateRef: 'input',
    placeholder: 'Mail',
    css: 'hide'
  }];


  isCheckboxActive(field, formGroup){
    return field.type == 'checkbox' && !!formGroup.get(field.id).value;
  }

  onInputChanged($event, field, val, formGroup){
    $event.stopPropagation();
    $event.preventDefault();

    const formControl = formGroup.get(field.id);
    switch(field.type){
      case 'radio':
        field.value = val;
        formControl.value = val;
        break;

      case "checkbox":
        val = field.value = formControl.value = Number($event.target.checked);
        break;

      default:
        field.value = val;
        break;
    }

    const relevantFields = this.notesFields.filter(({id}) => (field.attachedFieldIds || []).includes(id));

    relevantFields.forEach((field) => {
      const isFalse = !parseInt(val);
      if(isFalse){
        formGroup.get(field.id).disable();
        field.css += ' hide';
        return;
      }

      formGroup.get(field.id).enable();
      field.css = field.css.split(" ").filter((item) => item != 'hide').join(' ');
    });
  }

  @ViewChild('recapitulatif') recap;
  getFields(): {id: string; name: string; stepIndx: number; format?: string; nestedControl?: boolean, dependantStepIndexes?: number[]}[]{
    const fields = [
      {
        id: 'type_transaction_id',
        name: 'transaction',
        stepIndx: 1
      }, {
        id: 'bien_code_postal',
        name: 'code_postal',
        stepIndx: 1
      },{
        id: 'type_bien_id',
        name: 'bien',
        stepIndx: 2
      }, {
        id: 'type_construction_id',
        name: 'construction',
        stepIndx: 3
      }, {
        id: 'type_surface_id',
        name: 'surface',
        stepIndx: 4
      }, {
        id: 'proprietaire_telephone',
        name: 'phone_number',
        format: 'asIs',
        stepIndx: 5,
        dependantStepIndexes: [6]
      }, {
        id: 'proprietaire_nom',
        name: 'complete_name',
        format: 'asIs',
        stepIndx: 5,
        dependantStepIndexes: [6]
      }, {
        id: 'proprietaire_email',
        name: 'mail',
        format: 'asIs',
        stepIndx: 5,
        dependantStepIndexes: [6]
      }, {
        id: 'proprietaire_telephone',
        name: 'phone_number',
        format: 'asIs',
        stepIndx: 6,

      }, {
        id: 'proprietaire_nom',
        name: 'complete_name',
        format: 'asIs',
        stepIndx: 6,
      },  {
        id: 'bien_adresse',
        name: 'adresse_complete',
        format: 'asIs',
        stepIndx: 6
      }, {
        id: 'note',
        name: 'note',
        format: 'asIs',
        stepIndx: 6
      },{
        id: 'sur_place',
        name: 'note2_client',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'locataire',
        name: 'note2_nomlocataire',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'telLocataire',
        name: 'note2_tellocataire',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'chauffage_collectif',
        name: 'note2_chauffage',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'nom_syndic',
        name: 'note2_syndicname',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'tel_syndic',
        name: 'note2_syndictel',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'mail_syndic',
        name: 'note2_syndicmail',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'surface',
        name: 'note2_surface',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'annee_construction_exact',
        name: 'note2_construction',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'facturer_pour',
        name: 'note2_facturation',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'autre_sur_place',
        name: 'note2_autre',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'nom_sur_place',
        name: 'note2_cleaddress',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'tel_sur_place',
        name: 'note2_cletel',
        nestedControl: true,
        stepIndx: 6
      },{
        id: 'mail_sur_place',
        name: 'note2_clemail',
        nestedControl: true,
        stepIndx: 6
      }, {
        id: 'proprietaire_email',
        name: 'mail',
        format: 'asIs',
        stepIndx: 6

      }];
    return fields;
  }


  public get diagnostiquesObligatoireEnPossession() : any[] {
    if(!this.diagnostiques) return [];
    if(!this.diagnostiques.length) return [];

    return this.diagnostiques.filter(diag => diag.obligatoire == 1 && diag.possession);
  }




  constructor (
    private service : DataService,
    private fb : FormBuilder,
    private http : HttpClient,
    private route : ActivatedRoute
  ){

    this.Reservation = this.fb.group({
      step1 : this.fb.group ({
        city: [null],
        code_postal: [null, Validators.required],
        transaction : [null, Validators.required],
      }),
      step2 : this.fb.group ({
        bien : [null, Validators.required],
      }),
      step3: this.fb.group ({
        construction  : [null, Validators.required],
      }),
      step4 : this.fb.group ({
        surface  : [null, Validators.required],
      }),
      step5 : this.fb.group ({
        complete_name :[ null ,Validators.required],
        phone_number : [null , [Validators.required,Validators.minLength(10) ] ],
        mail : [null, [Validators.email] ]
      }),
      step6 : this.fb.group ({
        complete_name : [null, Validators.required ] ,
        streetNum: [null, Validators.required],
        phone_number : [null, [Validators.required, Validators.minLength(10)] ],
        mail : [null, Validators.email ],
        note: [null],
        note2: this.fb.group({
          client: ["Proprietaire"],
          nomlocataire: [{value: null, disabled: true}, Validators.required],
          tellocataire: [{value: null, disabled: true}, [Validators.required, Validators.pattern("^[0-9]*$")]],
          chauffage: ["0"],
          syndicname: [null],
          syndictel: [null, Validators.pattern("^[0-9]*$")],
          syndicmail: [null, Validators.email],
          surface: [null, Validators.pattern("^[0-9]*$")],
          construction: [null, Validators.pattern("^[0-9]*$")],
          facturation: ['Proprietaire'],
          autre: [0],
          cleaddress: [{value: null, disabled: true}, Validators.required],
          cletel: [{value: null, disabled: true}, [Validators.required, Validators.pattern("^[0-9]*$")]],
          clemail: [{value: null, disabled: true}, Validators.email]
        }),

        adresse_complete : [null],

      }),
      step7 : this.fb.group ({
        stam : [null]
      }),
    });

    this.bindDependantFields(this.dependantFieldsValues());

    this.Agent = this.fb.group({
      complete_name :[null,Validators.required],
      phone_number : [null, [Validators.required, Validators.minLength(10)] ],
      mail : [null, [Validators.email]],
      agency : [null]
    });


    this.Notaire = this.fb.group({
      complete_name :[null,Validators.required],
      phone_number : [null, [ Validators.required,Validators.minLength(10) ] ],
      mail : [null, [Validators.email]],
    });

    this.RDV = this.fb.group({
      jour : [null],
      heure : [null]
    });

    this.jour = this.RDV.get('jour').value;
    this.heure =  this.RDV.get('heure').value;

    const id_agent = +this.route.snapshot.paramMap.get('id_agent');
    if(id_agent && id_agent > 0) {
      this.id_agent = id_agent;
      this.service.getAgentImmoByID(id_agent).subscribe(data => {
        if(data && data[0]) {
          this.Agent.patchValue({
            complete_name : data[0]['nom'] + ' ' + data[0]['prenom'],
            mail : data[0]['mail'] ,
            agency : data[0]['agence'],
            phone_number : data[0]['tel'],
          });
        }
      });
    }
  }

  dependantFieldsValues(){
    const _this = this;
    return [{
      sourceControl: _this.Reservation.get('step5').get('complete_name'),
      targetControl: _this.Reservation.get('step6').get('complete_name')
    }, {
      sourceControl: _this.Reservation.get('step5').get('phone_number'),
      targetControl: _this.Reservation.get('step6').get('phone_number')
    }, {
      sourceControl: _this.Reservation.get('step5').get('mail'),
      targetControl: _this.Reservation.get('step6').get('mail')
    }];
  }
  bindDependantFields(controlsMapping){
    controlsMapping.forEach(({sourceControl, targetControl}) => {
          sourceControl.valueChanges.subscribe((newVal) => {
            targetControl.patchValue(newVal);
          });
      });
  }

  getControl(formGroup: FormGroup, path: string){
    const keys: string[] = path.split('/');
    let control = AbstractControl;
    keys.forEach((key) => {
      (control as any) = formGroup.get(key);
    });

    return control;
  }

  ngOnInit()
  {
    this.service.getRefs().subscribe(refs => {
      this.refs = refs;
    });


    this.service.getRDV().subscribe(
      data =>
      {
        console.log(data);
        this.creneaux = data['creneau'];
        this.hours = this.creneaux[0];

      });

    this.initAdressAutocomplete()
  }


  onDaySelected(event : Event)
  {

    console.log(event.target["selectedIndex"]);


    let index:number    = event.target["selectedIndex"];

    this.hours =  this.creneaux [index-1];
  }


  info_agents()
  {
    console.log(this.Notaire.value);

    console.log(this.Agent.value);

  }


  async getAgentImmo(p : string )
  {
    console.log(p);

    if(p.length >= 10)
    {
      this.tel_agent = p ;
      console.log("it");
      this.service.postAgent(
        {
          "telephone" : p,
          "type_partenaire" : 1
        }
      ).subscribe( data =>{
        console.log(data);
        if( data && data.length)
        {


          if( data [0]['prenom'])
          {
            this.Agent.patchValue(
              {
                complete_name : data[0]['nom'] + ' ' + data [0]['prenom'],
                mail : data[0]['mail'] ,
                agency : data[0]['agence'],
                phone_number : data[0]['tel'],
              }
            );

          }
          else
          {
            this.Agent.patchValue(
              {
                complete_name : data[0]['nom'] ,
                mail : data[0]['mail'] ,
                agency : data[0]['agence'],
                phone_number : data[0]['tel'],
              }
            );

          }

          this.id_agent = data[0]['id'];
        } ;

      });


      await this.delay(500);

    }

  }


  async getNotaire(p : string )
  {
    console.log(p);

    if(p.length >= 10)
    {

      console.log("it");
      this.service.postAgent(
        {
          "telephone" : p,
          "type_partenaire" : 2
        }
      ).subscribe( data =>{
        console.log(data);
        if (data && data.length )
        {

          if( data [0]['prenom'])
          {
            this.Notaire.patchValue(
              {
                complete_name : data[0]['nom'] + ' ' + data [0]['prenom'],
                mail : data[0]['mail'],
                phone_number : data[0]['tel'],
              }
            );
            this.canSendNotaire = true;
          }
          else
          {
            this.Notaire.patchValue(
              {
                complete_name : data[0]['nom'] ,
                mail : data[0]['mail'],
                phone_number : data[0]['tel'],
              }
            );

          }

          this.id_notaire = data[0]['id'];
        }

      });

      await this.delay(500);

    }

  }





  //#region  Back

  BackPage1()
  {
    this.page_bien = false;
    this.page_transaction = true;
  }

  BackPage2()
  {
    this.page_construction = false;
    this.page_bien = true;
  }


  BackPage3()
  {
    this.page_surface = false;
    this.page_construction = true;
  }

  BackPage4()
  {
    this.page_adresse= false;
    this.page_surface = true;

  }

  BackPage5()
  {
    this.page_adresse= true;
    this.page_prix= false;
    this.page_nouvelleadresse = false;
  }

  GoBackFromPageDiagnos(){
    // this.page_surface = true;
    this.page_shortUserInfos = true;
    this.page_diagnostics = false;
  }

  GoBackFromPageRecap(){
    this.page_diagnostics= true;
    this.page_recap = false;
  }

  GoBackFromPageRDV(){
    this.page_recap = true;
    this.page_reservation = false;
  }

  GoBackFromPageCoordonees(){
    this.page_prix = false;
    this.page_reservation = true;
  }

  GoBackFromPageRapports(){
    this.page_rapports = false;
    this.page_prix = true;
  }

  GoBackFromPageAgent(){
    this.page_agent = false;
    this.page_rapports = true;
  }

  GoBackFromPageNotaire(){
    this.page_notaire = false;
    this.page_rapports = true;
  }

  BackPagePrix()
  {
    this.page_diagnostics = false;
    this.page_prix = true;
  }

  BackPageDiagnostics()
  {
    this.page_diagnostics = true;
    this.page_recap = false;
  }

  BackPageRecap()
  {
    this.page_recap = true;
    this.page_reservation = false;

  }

  BackFromAgent()
  {
    this.page_rapports = true;

    this.page_agent = false ;
  }

  BackFromNotaire()
  {
    if(this.send_agent)
    {
      this.page_notaire = false;
      this.page_agent = true;
    }

    else
    {
      this.page_rapports = true;
      this.page_notaire = false ;
    }

  }

  BackPageReservation()
  {
    this.page_rapports = false;
    this.page_reservation = true;
  }


  //#endregion

  //#region  Go


  selectUniqueOption($event: MouseEvent, indx: number, cbk?: () => void){
    const target = $event.target as HTMLElement,
      // check if element is not the radio but radio is already checked
      isSelected = !!target.offsetParent && (target.parentElement.firstChild as HTMLInputElement).checked;
    if(isSelected){
      if(cbk)
        return cbk.bind(this)();

      this[`GoPage${indx + 1}`]();
    }

  }

  saveData(dataObj?): Promise<any>{
    this.dataObj = {...this.dataObj, ...dataObj};
    return new Promise((resolve) => {
      this.service.postStep(this.dataObj).subscribe((dto) => {
        console.log('done');
        this.dataObj = {...this.dataObj, ...(!isNaN(Number(dto.insert_mission)) ? {id: dto.insert_mission} : {})};
        resolve();
      });
    })
  }

  keyValPair(indx: number){
    const obj = {},
      fields = this.getFields();
    fields.forEach((field, i) => {
      if((field.dependantStepIndexes || []).includes(indx)  || field.stepIndx == indx){
        const names = field.nestedControl ? field.name.split('_') : [field.name];

        let fieldValue: AbstractControl = this.Reservation.get(`step${field.stepIndx}`);

        if(names.length == 1)
          fieldValue = !fieldValue.disabled ? fieldValue.get(field.name).value : null;

        else
          names.forEach((name, i) => {
            if(typeof(fieldValue) != 'object')
              return;

            fieldValue = fieldValue.get(name);
            if(i == names.length - 1)
              fieldValue = !fieldValue.disabled ? fieldValue.value : null;
        });

        if(fieldValue !== null && typeof(fieldValue) == 'object')
          return;

        const oldVal = obj[field.id],
            newVal = isNaN(parseInt(fieldValue as any)) || field.format == 'asIs' ? fieldValue : parseInt(fieldValue as any);
        obj[field.id] = oldVal && oldVal != newVal ? `${oldVal}; ${newVal}` : newVal;
      }

    });
    return obj;
  }
  share(type: 0 | 1 | 2){
    const text = this.recap.nativeElement.innerText.replaceAll('\n', '%0a');
    switch(type){
      case ShareChannel.Whatsapp:
        window.open(`https://api.WhatsApp.com/send?text=${text}`, '_blank');
        break;

      case ShareChannel.Email:
        window.open(`mailto:?body=${text}`, '_blank');
        break;

      case ShareChannel.Sms:
        window.open(`sms:&body=${text}`, '_self');
        break;
    }

  }

  shareMail(){

  }
  GoPage2() {
    this.page_bien = true;
    this.page_transaction = false;
    // this.updateCity();
    console.log('page2');
    this.dataObj = {...this.dataObj, ...this.keyValPair(1), id_agent: this.id_agent};
    this.saveData();
  }

  GoPage3()
  {
    this.page_construction = true;
    this.page_bien = false;
    console.log('page3');
    this.dataObj = {...this.dataObj, ...this.keyValPair(2)};
    this.saveData();
  }


  GoPage4()
  {
    this.page_surface = true;
    this.page_construction = false;
    this.page_shortUserInfos = false;
    console.log('page4');
    this.dataObj = {...this.dataObj, ...this.keyValPair(3)};
    this.saveData();
  }

  // personal user basic info (nom, tel, mail)
  GoPage5(){
    this.page_shortUserInfos = true;
    this.page_surface = false;
    this.dataObj = {...this.dataObj, ...this.keyValPair(4)};
    this.saveData();
  }

  GoPage6()
  {
    this.page_prix = true;
    this.page_nouvelleadresse = false;
    this.page_adresse = false;
    console.log(this.Reservation.value);

    console.log("Waht bug");

    console.log( this.Reservation.get('step7').value);
    this.dataObj = {...this.dataObj, ...this.keyValPair(5)};
    this.saveData();

  }


  // When we go to the page of diagnostics and price , we launch two HTTP POST requests
  async GoPageDiagnostics()
  {
    // save the last rdv step before summarizing diagnosis
    this.dataObj = {...this.dataObj, ...this.keyValPair(5)};
    await this.saveData();
    this.page_shortUserInfos = false;
    this.page_nouvelleadresse = false;
    this.page_adresse = false;
    this.page_surface = false;
    this.isLoadingNextPage = true;
    this.diagnostiques_necessaires = [];
    this.diagnostiques_obligatoires = [];
    this.diagnostiques =[];
    let surface_numerique = this.refs.type_surface.filter(t => +t.id == +this.Reservation.get('step4').get('surface').value).pop().equivalent;
    this.service.getDiagnostics(
      {
        "surface" : surface_numerique,
        "type_bien_id": this.Reservation.get('step2').get('bien').value,
        "type_transaction_id" : this.Reservation.get('step1').get('transaction').value ,
        "code_postal": this.Reservation.get('step1').get('code_postal').value,
        "date_construction": this.Reservation.get('step3').get('construction').value,
        "installation_electrique": 0,
        "installation_gaz" : 0,
        "surface_annexe": 1
      }
    ).subscribe( data=>  {
      console.log(data);
      this.diagnostiques_obligatoires = [];
      data.forEach((value) => {

        this.diagnostiques_obligatoires.push(value['id']);
        console.log('oblige');
      });
    });

    await this.delay(1000);

    this.diagnostiques_necessaires = this.diagnostiques_obligatoires;


    console.log( this.diagnostiques_obligatoires);



    this.refs.diagnostiques.forEach((value) => {
      console.log(value);
      var temp =  {};

      if( this.diagnostiques_obligatoires.find( x => x== value['id']))
        temp= {"id": value['id'], "name": value['name'],"obligatoire": 1, "possession": 0, option : 0};
      else
        temp= {"id": value['id'], "name": value['name'],"obligatoire": 0, "possession": 0, option : 0};
      this.diagnostiques.push(temp);
    });

    this.diagnostiques.sort((a,b) => {
      return (+b.obligatoire) - (+a.obligatoire)
    })
    console.log(this.diagnostiques);

    console.log('pagediagnos');



    await this.delay(500);

    this.getPrix();
    this.page_diagnostics = true;
    // this.page_prix = false;
    this.isLoadingNextPage = false;
  }


  updateCity(address?: {city: string; code_postal: number;}): void{
    this.Reservation.get('step1').patchValue(address);

    this.cityUpdated();
  }

  cityUpdated(){
    this.SearchAdressStatus.noResults = false;
    this.hideAddressMenu = true;
  }

  //On va a la page prix mais enregistrerl'adresse complete
  GoPagePrix()
  {
    this.page_prix = true;
    this.page_reservation = false;

    const rdv_jour = this.computeRdv();
    this.dataObj = {...this.dataObj, rdv_jour};
    this.saveData();

  }

  computeRdv(){
    console.log(this.RDV.value);
    let date, jour = this.RDV.get('jour').value, hour = this.RDV.get('heure').value, date_hour;
    if(jour){
      date = new Date(jour  * 1000).toLocaleDateString("fr-Fr");
      jour = date.split('/');
    }


    if(hour)
      date_hour = new Date( hour * 1000).toLocaleTimeString("fr-Fr");


    console.log(date_hour);
    console.log(date);
    console.log(jour);

    // heure = date_hour.split()

    this.rdv_jour = ( (jour ? (jour[2] +'-' + jour[1]+ '-' + jour[0]) : '') + ' ' + (date_hour || '')).trim().toString();
    // await this.saveData({});
    this.jour = this.RDV.get('jour').value;
    this.heure = this.RDV.get('heure').value;


    return this.rdv_jour;
  }

  GoPageReservation()
  {
    this.page_recap = false;
    this.page_reservation = true;
  }


  async GoPageRapports()
  {

    const streetNum = this.Reservation.get('step6').get('streetNum').value,
      city = this.Reservation.get('step1').get('city').value;

    this.Reservation.get('step6').patchValue({
      adresse_complete: `${streetNum}${city ? `, ${city}` : ''}`
    });

    this.dataObj = {...this.dataObj, ...this.keyValPair(6)};
    await this.saveData();

    if(this.id_agent) {
      await this.GoPageConfirmation();
    } else {
      this.page_rapports = true;

    }
    this.page_prix = false;


  }



  GoPageRecap()
  {
    this.page_diagnostics = false;
    this.page_recap = true;
    this.noms_diagnostiques_necessaires = [];

    for( let i=0 ; i < this.diagnostiques.length ; i++ )
    {
      for (let j =0; j< this.diagnostiques_necessaires.length; j++)
      {
        if( this.diagnostiques_necessaires[j] == this.diagnostiques[i].id)
          this.noms_diagnostiques_necessaires.push(this.diagnostiques[i].name);


      }
    }

    console.log(this.noms_diagnostiques_necessaires);
  }

  //depend si il veut re,plir les parametres d'un agent
  GoPageAgents()
  {
    this.page_rapports = false;
    if( this.send_agent)
    {
      this.page_agent = true;
    }
    else
    {

      if (this.send_notaire) {
        this.page_notaire = true;
      } else {
        this.GoPageConfirmation();
      }

    }

  }

  GoFromAgent()
  {
    this.page_agent = false ;
    if (this.send_notaire) {
      this.page_notaire = true;
    }
    else {
      this.GoPageConfirmation();
    }
  }

  GoFromNotaire()
  {

    this.GoPageConfirmation();
  }



  async GoPageConfirmation()
  {

    if(this.id_agent == null && this.send_agent)
      await this.createAgentImmo();


    await this.delay(1000);

    if(this.id_notaire == null && this.send_notaire)
      await this.createNotaire();


    this.dataObj = {...this.dataObj, envoi_rapport_agent: this.send_agent, id_agent: this.id_agent, envoi_rapport_notaire: this.send_notaire, id_notaire: this.id_notaire, facturer_pour: this.id_agent ? 'agent' : 'proprietaire'};
    // await this.saveData();

    await this.delay(1000);

    this.checkConfirmation().subscribe(async data => {
      if(data && !data.error) {
        this.isConfirmed = true;
      }
      else {
        this.isConfirmed = false;
      }

      if(this.isConfirmed)
      {
        console.log('c bon');
        this.page_rapports = false ;
        this.page_agent = false;
        this.page_notaire = false;
        this.page_confirmation = true;
      }
      else {
        alert('Veuillez réessayer plus tard');
      }
    });

  }


  checkConfirmation() {
    return this.service.postConfirmation(this.dataObj);
  }

  public agents()
  {
    console.log(this.send_agent);
    console.log(this.send_notaire);
  }


  public async createAgentImmo()
  {
    return new Promise((resolve) => {
      console.log("Agent");
      console.log(this.Agent.get('complete_name').value + ' ' + this.Agent.get('phone_number').value + ' '  + this.Agent.get('agency').value );
      this.service.createAgent(
        {
          "nom" : this.Agent.get('complete_name').value,
          "tel" : this.Agent.get('phone_number').value,
          "mail" :  this.Agent.get('mail').value,
          "agence" :  this.Agent.get('agency').value,
          "type_partenaire" : 1,
          "prenom" : null
        }
      ).subscribe(data =>
      {
        this.id_agent = data['id'];
        console.log(data);

        console.log(data['id']);
        resolve();
      });
    })
  }


  public createNotaire(): Promise<any> {
    return new Promise((resolve) => {
      try{
        this.service.createAgent(
          {
            "nom" : this.Notaire.get('complete_name').value ,
            "tel" : this.Notaire.get('phone_number').value,
            "mail" :  this.Notaire.get('mail').value,
            "agence" : null ,
            "type_partenaire" : 2

          }
        ).subscribe(data =>
        {
          this.id_notaire = data['id'];
          console.log(data);
          console.log(data['id']);
          resolve();
        })
      }
      catch(e)
      {
        console.log(e);
      }

    });
  }


  stam(s : any)
  {
    console.log('here');
    console.log(s);
    console.log(this.Reservation.get('step7').get('diags'));
  }



  /*
  Quand le client cherche une adresse ,il recoit la liste des adresses contenant ce qu il a rentre
  */
  initAdressAutocomplete()
  {
    this.adresses = this.Reservation.get('step1').get('code_postal').valueChanges.pipe(
      debounceTime(1000),
      startWith(''),
      switchMap((s) => {
        if( s.length >=3) {
          this.SearchAdressStatus = { running:true, ended:false };

          return this.service.postAdress(s)
        } else {
          return new Observable();
        }
      }),
      map((response:any) => {
        this.hideAddressMenu = false;
        if(response && response.adresses && response.adresses.length) {
          this.SearchAdressStatus = { running:false, ended:true };
          this.selectedCommune = response.adresses[0].adresse;
          console.log(response);
          return response['adresses']
        }
        else {
          this.SearchAdressStatus = { running:false, ended:true, noResults: true };
          console.log("rien dans le input");
          return []
        }

      })
    );


    this.Reservation.get('step1').get('city').valueChanges.pipe(
      debounceTime(500)
    ).subscribe(() => {
      this.cityUpdated();
    });

  }




  getPrix()
  {
    this.service.postPrix(
      {
        "type_surface_id": +this.Reservation.get('step4').get('surface').value,
        "code_postal": `${this.Reservation.get('step1').get('code_postal').value}`,
        "id_agent": this.id_agent,
        "diagnostics": this.diagnostiques_necessaires,

      }
    ).subscribe ( data =>
    {
      this.prix = data['prix'];
      this.saveData({prix: this.prix, diagnostics: this.diagnostiques_necessaires});
      console.log('prix');
      console.log(this.prix);
    });
  }


  possession( index : any)
  {
    this.diagnostiques[index].possession = 1;


    const i = this.diagnostiques_necessaires.indexOf(this.diagnostiques[index].id);
    if (i !== -1) {
      this.diagnostiques_necessaires.splice(i, 1);
    }

    console.log(this.diagnostiques[index].id);

    console.log(this.diagnostiques_necessaires);
    this.getPrix();
  }


  not_possession( index : any)
  {
    this.diagnostiques[index].possession = 0;


    this.diagnostiques_necessaires.push(this.diagnostiques[index].id);

    console.log(this.diagnostiques[index].id);

    console.log(this.diagnostiques_necessaires);

    this.getPrix();
  }

  option( index : any )
  {
    this.diagnostiques[index].option = 1;

    this.diagnostiques_necessaires.push(this.diagnostiques[index].id);

    console.log(this.diagnostiques[index].id);

    console.log(this.diagnostiques_necessaires);

    this.getPrix();
  }



  not_option( index : any )
  {
    this.diagnostiques[index].option = 0;

    const i = this.diagnostiques_necessaires.indexOf(this.diagnostiques[index].id);
    if (i !== -1) {
      this.diagnostiques_necessaires.splice(i, 1);
    }

    console.log(this.diagnostiques[index].id);

    console.log(this.diagnostiques_necessaires);

    this.getPrix();
  }


  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

}

export enum ShareChannel{
  Whatsapp,
  Email,
  Sms
}
